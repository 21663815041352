// Sidebar.js
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../Styles/Sidebar.css';
import profileImage from '../images/profile.jpg';
import MediumBlog from '../components/MediumBlog';

const Sidebar = () => {
  const location = useLocation();
  const [menuActive, setMenuActive] = useState(false);

  return (
    <>
      {/* Кнопка для открытия/закрытия боковой панели на мобильных устройствах */}
      <button
        className="menu-toggle"
        onClick={() => setMenuActive(!menuActive)}
      >
        ☰
      </button>

      {/* Боковая панель */}
      <div className={`sidebar ${menuActive ? "active" : ""}`}>
        <div className="profile">
          <Link to="/" className="profile-link" onClick={() => setMenuActive(false)}>
            <img
              src={profileImage}
              alt="Profile"
              className="profile-image"
              onDragStart={(e) => e.preventDefault()}
              onContextMenu={(e) => e.preventDefault()}
            />
            <h1>ALEKSEI ALEINIKOV</h1>
            <h2 className="subtitle">Full-Stack Developer</h2>
          </Link>
        </div>
        <div className="links-section">
          <ul className="sidebar-links">
            <li>
              <Link
                to="/offer"
                className={location.pathname === "/offer" ? "active-link" : ""}
                onClick={() => setMenuActive(false)}
              >
                OFFER
              </Link>
            </li>
            <li>
              <Link
                to="/highlights"
                className={location.pathname === "/highlights" ? "active-link" : ""}
                onClick={() => setMenuActive(false)}
              >
                HIGHLIGHTS
              </Link>
            </li>
            <li>
              <Link
                to="/hire_me"
                className={location.pathname === "/hire_me" ? "active-link" : ""}
                onClick={() => setMenuActive(false)}
              >
                HIRE ME
              </Link>
            </li>
            <div className="link-group-separator"></div>
            <li>
              <Link
                to="/cv"
                className={location.pathname.startsWith("/cv") ? "active-link" : ""}
                onClick={() => setMenuActive(false)}
              >
                CV
              </Link>
            </li>
            <li>
              <Link
                to="/portfolio"
                className={location.pathname === "/portfolio" ? "active-link" : ""}
                onClick={() => setMenuActive(false)}
              >
                PORTFOLIO
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className={location.pathname === "/contact" ? "active-link" : ""}
                onClick={() => setMenuActive(false)}
              >
                CONTACT FORM
              </Link>
            </li>
            <li>
              <a
                href="https://medium.com/@aleksei.aleinikov.gr"
                target="_blank"
                rel="noopener noreferrer"
                className="blog-link"
                onClick={() => setMenuActive(false)}
              >
                PERSONAL BLOG
              </a>
            </li>
          </ul>
          <div className="contact">
            <p>GET IN TOUCH:</p>
            <div className="social-icons">
              <a
                href="https://www.linkedin.com/in/your-profile/"
                className="icon linkedin"
                aria-label="LinkedIn profile"
              ></a>
              <a
                href="https://github.com/your-github/"
                className="icon github"
                aria-label="GitHub profile"
              ></a>
            </div>
          </div>
          <MediumBlog />
        </div>
      </div>
    </>
  );
};

export default Sidebar;
