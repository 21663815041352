// Resumes.js
import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import pythonIcon from '../icons/python.png'; 
import javascriptIcon from '../icons/js.png';
import devopsIcon from '../icons/devops.png';
import iosIcon from '../icons/ios.png';
import servicenowIcon from '../icons/now.png';
import fullstackIcon from '../icons/fullstack.png';
import frontendIcon from '../icons/react.png';
import blockchainIcon from '../icons/blockchain.png';
import networkIcon from '../icons/network.png';
import '../Styles/Resume.css'; 

const navItems = [
    { path: "/cv/devops-engineer", label: "Cloud DevOps Engineer", icon: devopsIcon },
    { path: "/cv/python-developer", label: "Python Developer", icon: pythonIcon },
    { path: "/cv/javascript-developer", label: "JavaScript Developer", icon: javascriptIcon },
    { path: "/cv/network-engineer", label: "Network Automation Engineer", icon: networkIcon },
    { path: "/cv/servicenow-developer", label: "ServiceNOW Developer", icon: servicenowIcon },
    // { path: "/cv/ios-developer", label: "iOS Developer", icon: iosIcon },
    // { path: "/cv/frontend-developer", label: "Frontend Developer", icon: frontendIcon },
    // { path: "/cv/fullstack-developer", label: "FullStack Developer", icon: fullstackIcon },
    { path: "/cv/blockchain-engineer", label: "Full Stack Blockchain Developer", icon: blockchainIcon },

    // { path: "/cv/data-engineer", label: "Data Engineer", icon: devopsIcon }
];

const Resumes = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleContactMe = () => {
    navigate('/contact'); 
  };

  return (
    <div className="navbar">
      <button className="back-button" onClick={() => navigate('/')}>Back</button>
      <h2>Choose one of CV</h2>
      <div className="navbar-section">
        {navItems.map((item) => (
          <Link
            key={item.path}
            to={item.path}
            className={`navbar-item ${location.pathname === item.path ? 'active' : ''}`} 
          >
            <img src={item.icon} alt={item.label} className="icon" />
            {item.label}
          </Link>
        ))}
      </div>
      <div className="contact-me-container">
        <button className="contact-me-button" onClick={handleContactMe}>
          <img src="/envelope.png" alt="Contact" className="contact-me-button-icon" />
          Contact Me
        </button>
      </div>
    </div>
  );
};

export default Resumes;


