// HireMe.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './Sidebar';
import '../Styles/Hireme.css';

const HireMe = () => {
  const [isPageVisible, setIsPageVisible] = useState(false);
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  useEffect(() => {
    const pageTimer = setTimeout(() => setIsPageVisible(true), 100);
    const infoTimer = setTimeout(() => setIsInfoVisible(true), 200);

    return () => {
      clearTimeout(pageTimer);
      clearTimeout(infoTimer);
    };
  }, []);

  return (
    <div className="home">
      <Sidebar />
      <div className={`Hireme-content fade-in-page ${isPageVisible ? 'active' : ''}`}>
        <h2>Hire Me</h2>
        <p>
          While I am currently committed to my current role and focused on delivering impactful results,
          I am always open to discussing intriguing collaboration opportunities that align with my skills
          and interests. Feel free to reach out!
        </p>
        <div className="contact-button">
          <Link to="/contact">
            <button className="contact-link-button">Go to Contact Form</button>
          </Link>
        </div>
        <div className={`contact-info fade-in-element ${isInfoVisible ? 'active' : ''}`}>
          <ul>
            <li>
              <span className="icon linkedin-icon"></span>
              <a
                href="https://www.linkedin.com/in/aleksei-aleinikov-78195911a/"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </a>
            </li>
            <li>
              <span className="icon github-icon"></span>
              <a
                href="https://github.com/LF3551"
                target="_blank"
                rel="noopener noreferrer"
              >
                GitHub
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HireMe;
