// Portfolio.js

import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import '../Styles/Portfolio.css';
import { loadData } from '../utils/loaddata';
const Portfolio = () => {
  const [isPageVisible, setIsPageVisible] = useState(false);
  const [petProjects, setPetProjects] = useState([]);
  const [uniqueTechnologies, setUniqueTechnologies] = useState([]);
  const [selectedTech, setSelectedTech] = useState([]);


  useEffect(() => {
    const pageTimer = setTimeout(() => setIsPageVisible(true), 100);
    return () => clearTimeout(pageTimer);
  }, []);
  
  const toggleSelectTech = (tech) => {
    setSelectedTech((prev) =>
      prev.includes(tech) ? prev.filter((t) => t !== tech) : [...prev, tech]
    );
  };
  useEffect(() => {
    async function fetchData() {
      setPetProjects([]); // Очистка предыдущих данных
      const allData = await loadData();
      const allProjects = allData.flatMap((file) => file.pet_projects || []);
      const uniqueProjects = Array.from(
        new Map(
          allProjects.map((project) => [
            `${project.title}_${project.description}`,
            project
          ])
        ).values()
      );
      setPetProjects(uniqueProjects);
  
      const allTechnologies = uniqueProjects.flatMap((project) => project.technologies || []);
      setUniqueTechnologies(Array.from(new Set(allTechnologies)));
    }
  
    fetchData();
  }, []);
  
  


  return (
    <div className="home">
      <Sidebar />
      <div className={`portfolio-content fade-in-page ${isPageVisible ? 'active' : ''}`}>
        <h2>Portfolio</h2>
        <p className="portfolio-intro">
          Explore a curated collection of my projects and achievements, showcasing my expertise in Full-Stack Development, DevOps, Python Optimization, and more.
        </p>

        <div className="portfolio-section">
        <h3 className="section-heading">Enterprise-Grade Solutions</h3>
<p className="section-description">
  This section highlights my professional experience in building high-quality solutions for enterprise needs, including DevOps automation, AI-driven tools, and scalable system optimizations.
</p>


          <div className="portfolio-grid">

          <div className="portfolio-card">
  <div className="card-image">
    <img src="/RAG.png" alt="RAG-Based Chatbot Data Uploader" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()} />
  </div>
  <div className="card-content">
    <h4>RAG-Based Chatbot Data Uploader</h4>
    <p>
      <span className="highlight">Developed a sophisticated data uploader</span> for RAG-based chatbots, supporting <span className="highlight-blue">PDF document uploads</span> and employing <span className="highlight-green">adaptive chunking strategies</span>. The system autonomously evaluated and selected optimal chunking methods using <span className="highlight-bold">LLMs</span>, improving chatbot efficiency and response quality while reducing redundancies.
    </p>
    <div className="card-tags">
      <span>Python</span>
      <span>LLM</span>
      <span>RAGAS</span>
      <span>PDF Processing</span>
    </div>
  </div>
</div>

<div className="portfolio-card">
  <div className="card-image">
    <img src="/QA.png" alt="Software Development Quality Platform" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()} />
  </div>
  <div className="card-content">
    <h4>Software Development Quality Platform</h4>
    <p>
      Built an <span className="highlight">AI-powered platform</span> using <span className="highlight-blue">neural networks</span> to analyze business requirements, user stories, and test cases. The system identified <span className="highlight-red">missing or redundant elements</span>, estimated test coverage, and provided <span className="highlight-green">actionable insights</span>, improving development efficiency and software quality.
    </p>
    <div className="card-tags">
      <span>Neural Networks</span>
      <span>Python</span>
      <span>Task Optimization</span>
      <span>Test Coverage Analysis</span>
    </div>
  </div>
</div>

<div className="portfolio-card">
  <div className="card-image">
    <img src="/devops.png" alt="GitLab CI/CD and Microservices Deployment" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()} />
  </div>
  <div className="card-content">
    <h4>GitLab CI/CD and Microservices Deployment</h4>
    <p>
      Developed robust GitLab CI/CD pipelines tailored for <span className="highlight-green">multiple environments</span>, integrating <span className="highlight-blue">ArgoCD</span> for seamless microservices deployment. Designed and maintained <span className="highlight-bold">Helm charts</span> for deploying complex workloads, including StatefulSets and Deployments. Automated the creation and storage of container images in <span className="highlight">JFrog</span>, enabling artifact-based reporting and audit trails.
    </p>
    <div className="card-tags">
      <span>GitLab CI/CD</span>
      <span>ArgoCD</span>
      <span>Helm</span>
      <span>JFrog</span>
    </div>
  </div>
</div>

<div className="portfolio-card">
  <div className="card-image">
    <img src="/LOG.png" alt="Centralized Log Aggregation System" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()} />
  </div>
  <div className="card-content">
    <h4>Centralized Log Aggregation System</h4>
    <p>
      Enhanced a system to aggregate and structure logs from <span className="highlight-blue">multiple sources</span>, transforming raw data into <span className="highlight-green">actionable insights</span> with <span className="highlight-bold">real-time visual dashboards</span>. Optimized processing workflows reduced latency by <span className="highlight-red">50%</span> and improved log query efficiency for enterprise monitoring.
    </p>
    <div className="card-tags">
      <span>Python</span>
      <span>Pandas</span>
      <span>Matplotlib</span>
    </div>
  </div>
</div>

<div className="portfolio-card">
  <div className="card-image">
    <img src="/SN.png" alt="ServiceNow Customization" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()} />
  </div>
  <div className="card-content">
    <h4>ServiceNow Optimization and Customization</h4>
    <p>
      Optimized <span className="highlight">ServiceNow scripts</span> and database queries, improving data validation and processing efficiency by <span className="highlight-green">75%</span>. Streamlined workflows enhanced <span className="highlight-green">ITSM performance</span> and reduced system resource utilization.
    </p>
    <div className="card-tags">
      <span>ServiceNow</span>
      <span>JavaScript</span>
      <span>ITSM</span>
    </div>
  </div>
</div>

<div className="portfolio-card">
  <div className="card-image">
    <img src="/WEB.png" alt="Responsive Web Application" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()} />
  </div>
  <div className="card-content">
    <h4>Responsive and Scalable Web Application</h4>
    <p>
      Contributed to the development of an <span className="highlight">interactive e-commerce platform</span>, focusing on optimizing the <span className="highlight-blue">user interface</span> to improve responsiveness and usability. Refactored page code to reduce load times and resource usage, resulting in <span className="highlight-green">smoother interactions</span> and faster page performance.
    </p>
    <div className="card-tags">
      <span>React</span>
      <span>Node.js</span>
      <span>GCP</span>
    </div>
  </div>
</div>

<div className="portfolio-card">
  <div className="card-image">
    <img src="/AGGR.png" alt="Advanced Data Aggregation Tool" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()} />
  </div>
  <div className="card-content">
    <h4>Advanced Data Aggregation Optimization</h4>
    <p>
      Enhanced an existing <span className="highlight-green">real-time data aggregation</span> and visualization system by optimizing ETL pipelines and restructuring code, improving processing speeds by <span className="highlight-red">40%</span>. Refined dashboard designs to deliver <span className="highlight-green">more actionable insights</span> for enterprise decision-making.
    </p>
    <div className="card-tags">
      <span>Python</span>
      <span>Pandas</span>
      <span>Flask</span>
      <span>Matplotlib</span>
    </div>
  </div>
</div>




          </div>
        </div>







        <div className="pet-projects-section">
        <h3 className="section-heading">My Projects</h3>
<p className="section-description">
  A showcase of personal and collaborative projects where I applied modern technologies to solve diverse challenges and deliver innovative results.
</p>


          <div className="pet-projects-grid">
            {petProjects.map((project, index) => (
              <div key={index} className="pet-project-card">
                <div className="pet-card-image">
                  {project.images && project.images.length > 0 ? (
                    <img src={project.images[0]} alt={project.title} onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()} />
                  ) : (
                    <div className="placeholder-image">No Image</div>
                  )}
                </div>
                <div className="pet-card-content">
                  <h4>{project.title}</h4>
                  <p>{project.description}</p>
                  <div className="pet-card-tags">
                    {project.technologies.map((tech, techIndex) => (
                      <span key={techIndex}>{tech}</span>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>




        <div className="unique-technologies-section">
          <h3 className="section-heading">Unique Technologies</h3>
          <div className="unique-technologies">
            {uniqueTechnologies.map((tech, index) => (
             <span
             key={index}
             className={`tech-tag ${selectedTech.includes(tech) ? 'selected' : ''}`}
             onClick={() => toggleSelectTech(tech)}
           >
             {tech}
           </span>
            ))}
          </div>
        </div>






      </div>
    </div>
  );
};









export default Portfolio;
