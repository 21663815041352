// App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation  } from 'react-router-dom';
import Resumes from './components/Resumes';
import Offer from './components/Offer';
import Home from './Home';
import Portfolio from './components/Portfolio';
// import Services from './components/Services';
import HireMe from './components/Hireme';
import ContactForm from './components/ContactForm'; 
import Highlights from './components/Highlights';
import Resume from './components/Resume';
import pythonData from './data/python-developer.json';
import javascriptData from './data/javascript-developer.json';
import devopsData from './data/devops-engineer.json';
import iosData from './data/ios-developer.json';
import servicenowData from './data/servicenow-developer.json';
import fullstackData from './data/fullstack-developer.json';
import frontendData from './data/frontend-developer.json';
import blockchainData from './data/blockchain-engineer.json';
import networkData from './data/network-engineer.json';
import dataEngineer from './data/data-engineer.json';
import './Styles/App.css';
import welcome from './images/welcome-illustration.png';


const resumes = [
  { path: "/cv/python-developer", data: pythonData },
  { path: "/cv/javascript-developer", data: javascriptData },
  { path: "/cv/devops-engineer", data: devopsData },
  { path: "/cv/ios-developer", data: iosData },
  { path: "/cv/servicenow-developer", data: servicenowData },
  { path: "/cv/fullstack-developer", data: fullstackData },
  { path: "/cv/frontend-developer", data: frontendData },
  { path: "/cv/blockchain-engineer", data: blockchainData },
  { path: "/cv/network-engineer", data: networkData },
  { path: "/cv/data-engineer", data: dataEngineer },
];

const AppContent = () => {
  const location = useLocation();

  // Отображаем Resumes только на /cv и его подстраницах
  const showResumes = location.pathname.startsWith('/cv');
  useEffect(() => {
    const scrollContainer = document.querySelector('.content'); // Замените '.content' на ваш контейнер
    if (scrollContainer) {
      scrollContainer.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [location.pathname]);
  
  

  return (
    <div className={`app ${showResumes ? 'cv-page' : ''}`}>
      {showResumes && <Resumes />}
      <div className={`content ${showResumes ? 'cv-content' : 'default-content'}`}>
        <Routes key={location.pathname}>
          <Route path="/" element={<Home />} />
          <Route path="/offer" element={<Offer />} />
          <Route path="/hire_me" element={<HireMe />} />
          <Route path="/portfolio" element={<Portfolio />} />
          {/* Прячем пока не нужно */}
          {/* <Route path="/services" element={<Services />} /> */}
          <Route path="/highlights" element={<Highlights />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route
            path="/cv"
            element={
              <div className="welcome-message">
                <h1>Welcome to My CV Dashboard</h1>
                <p>Explore my professional journey by selecting a CV from the left panel.</p>
                <img
                  src={welcome}
                  alt="Welcome Illustration"
                  className="welcome-image"
                  draggable="false"
                  onContextMenu={(e) => e.preventDefault()}
                />
              </div>
            }
          />

          {resumes.map((resume) => (
            <Route
              key={resume.path}
              path={resume.path}
              element={<Resume {...resume.data} />}
            />
          ))}
        </Routes>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;