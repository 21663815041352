// Highlights.js
import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import '../Styles/Highlights.css';

const Highlights = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Запускаем анимацию через короткую задержку
    const timer = setTimeout(() => setIsVisible(true), 100);

    return () => clearTimeout(timer); // Очищаем таймер при размонтировании
  }, []);

  return (
    <div className="home">
      <Sidebar />
      <div className={`highlights-content fade-in ${isVisible ? 'active' : ''}`}>
        <h2>Highlights</h2>
        <p className="intro">
          Sharing knowledge and contributing to the tech community has always been a core part of my journey.
        </p>

        <div className="highlights-section">
          <h3>Technical Publications</h3>
          <p>
            I have had the privilege of publishing technical articles on some of the most reputable platforms in the developer community, including:
          </p>
          <ul className="publication-links">
            <li>
              <a href="https://blog.devgenius.io" target="_blank" rel="noopener noreferrer">Dev Genius</a>
            </li>
            <li>
              <a href="https://medium.datadriveninvestor.com" target="_blank" rel="noopener noreferrer">DataDrivenInvestor</a>
            </li>
            <li>
              <a href="https://javascript.plainenglish.io" target="_blank" rel="noopener noreferrer">JavaScript Plain English</a>
            </li>
            <li>
              <a href="https://medium.com/pythoneers" target="_blank" rel="noopener noreferrer">Pythoneers</a>
            </li>
            <li>
              <a href="https://levelup.gitconnected.com" target="_blank" rel="noopener noreferrer">Level Up Coding</a>
            </li>
          </ul>
          <p>
            These articles reflect my expertise in JavaScript, Python, and Full-Stack Development, as well as my ability to explain complex concepts in an accessible way.
          </p>
        </div>

        <div className="highlights-section book-section">
  <h3>Published Book</h3>
  <p>
  In 2024, I proudly released my first book, <span className="highlight">"Code Your Own Path"</span>, a culmination of 11 months of dedicated work. This practical guide is tailored for aspiring developers, covering essential topics such as coding skills, personal growth, time management, and networking. 
  The book is designed to empower developers to build a successful tech career on their own terms.
</p>

  <div className="book-content">
    <div className="book-links-container">
      <ul className="book-links">
        <li>
          <a href="https://www.amazon.com/gp/product/B0DNSLL5BL/ref=x_gr_bb_amazon" target="_blank" rel="noopener noreferrer">
            <div className="platform-card">
              <img src="/Amazon_icon.png" alt="Amazon" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()}/>
              <span>Amazon</span>
            </div>
          </a>
        </li>
        <li>
          <a href="https://www.lulu.com/shop/aleksei-aleinikov/code-your-own-path/ebook/product-zm4pmgm.html" target="_blank" rel="noopener noreferrer">
            <div className="platform-card">
              <img src="/lulu_icon.png" alt="Lulu" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()}/>
              <span>Lulu</span>
            </div>
          </a>
        </li>
        <li>
          <a href="https://books.apple.com/gr/book/code-your-own-path/id6738574508" target="_blank" rel="noopener noreferrer">
            <div className="platform-card">
              <img src="/apple_books_icon.png" alt="Apple Books" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()}/>
              <span>Apple Books</span>
            </div>
          </a>
        </li>
        <li>
          <a href="https://www.kobo.com/gr/en/ebook/code-your-own-path" target="_blank" rel="noopener noreferrer">
            <div className="platform-card">
              <img src="kobo_icon.jpg" alt="Kobo Books" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()}/>
              <span>Kobo Books</span>
            </div>
          </a>
        </li>
        <li>
          <a href="https://www.bol.com/nl/nl/p/code-your-own-path/9300000197821825/" target="_blank" rel="noopener noreferrer">
            <div className="platform-card">
              <img src="/bol_icon.png" alt="Bol" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()}/>
              <span>Bol</span>
            </div>
          </a>
        </li>
        <li>
          <a href="https://www.everand.com/book/795491183/Code-Your-Own-Path" target="_blank" rel="noopener noreferrer">
            <div className="platform-card">
              <img src="/everand_icon.png" alt="Everand" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()}/>
              <span>Everand</span>
            </div>
          </a>
        </li>
        <li>
          <a href="https://www.indigo.ca/fr-ca/code-your-own-path/16f97c8d-5e65-37a2-b3df-7fa7471e1f2a.html" target="_blank" rel="noopener noreferrer">
            <div className="platform-card">
              <img src="/indigo_icon.png" alt="Indigo" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()}/>
              <span>Indigo</span>
            </div>
          </a>
        </li>
      </ul>
    </div>
    <div className="book-image">
      <img src="/cyop.png" alt="Code Your Own Path" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()}/>
    </div>
  </div>
</div>





        <div className="highlights-section">
          <h3>Certifications</h3>
          <div className="certifications-icons">
            <div className="cert-item" title="Cisco CCNA">
              <img src="/ccna.png" alt="Cisco Certification" className="cert-icon" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()}/>
            </div>
            <div className="cert-item" title="Huawei HCIP">
              <img src="/hcip.png" alt="Huawei Certification" className="cert-icon" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()}/>
            </div>
            <div className="cert-item" title="SAFE Agile Certified">
              <img src="/SAFE.png" alt="Huawei Certification" className="cert-icon" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()}/>
            </div>
            <div className="cert-item" title="Azure Data fundamentals – Associate">
              <img src="/azure.png" alt="Azure Certification" className="cert-icon" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()}/>
            </div>
            <div className="cert-item" title="Google Cloud Professional Data Engineer">
              <img src="/CDE.png" alt="Google Cloud Certification" className="cert-icon" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()}/>
            </div>

          </div>

        </div>


        <div className="highlights-section">
  <h3>Google Cloud Achievements</h3>
  <p>
    I have been an active member of <span className="highlight">Google Cloud Skills Boost</span> since 2022, earning a place in the <strong>Diamond League</strong> with over <strong>120,000 points</strong>. Over this time, I have completed more than 1,000 hands-on labs, gaining extensive expertise in Google Cloud Platform (GCP) services.
  </p>
  <div className="gcp-profile">
    <div className="gcp-details">
      <p>
        My achievements showcase my advanced skills in application modernization, data analytics, and cloud infrastructure. You can view my full profile and achievements on Google Cloud Skills Boost:
      </p>
      <a
        href="https://www.cloudskillsboost.google/public_profiles/3f0276b2-6903-48d5-adf1-8831d5555c7e"
        target="_blank"
        rel="noopener noreferrer"
        className="gcp-link"
      >
        View My Google Cloud Profile
      </a>
    </div>
    <div className="gcp-image">
      <img src="/gcp_skill.png" alt="Google Cloud Diamond League Badge" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()}/>
    </div>
  </div>
</div>






        <div className="highlights-section">
          <h3>Community Engagement</h3>
          <p>
            Over the years, I have demonstrated my passion for continuous learning by initiating and organizing a book club within my organization. These sessions provided a platform for discussing technical, leadership, and productivity topics.
          </p>
          <ul className="engagement-list">
            <li>Organized and led 24+ book club sessions over several years, fostering meaningful discussions and knowledge-sharing.</li>
            <li>
              Delivered presentations as a speaker on influential books, including:
              <ul>
                <li>"The 5 AM Club" by Robin Sharma (focused on personal growth and productivity)</li>
                <li>"Attention Pays" by Neen James (centered on accountability and driving results)</li>
                <li>"The Everyday Hero Manifesto" by Robin Sharma (highlighting self-leadership and making an impact)</li>
              </ul>
            </li>
            <li>Encouraged collaboration and cross-functional engagement, improving team communication and fostering a culture of growth.</li>
          </ul>
          <p>
            These initiatives strengthened my skills in public speaking, mentoring, and inspiring teams, while also promoting a culture of continuous learning and improvement.
          </p>
        </div>








      </div>
    </div>
  );
};

export default Highlights;
