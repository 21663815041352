// ContactForm.js
import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import '../Styles/ContactForm.css';
import emailjs from 'emailjs-com';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    subject: 'General Inquiry',
  });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isVisible, setIsVisible] = useState(false); 

  const validateField = (field, value) => {
    switch (field) {
      case 'name':
        if (!value.trim()) return 'Name is required.';
        break;
      case 'email':
        if (!value.trim()) return 'Email is required.';
        if (!/\S+@\S+\.\S+/.test(value)) return 'Invalid email address.';
        break;
      case 'message':
        if (!value.trim()) return 'Message cannot be empty.';
        break;
      default:
        return '';
    }
    return '';
  };
  useEffect(() => {
    // Установить видимость через 100 мс после монтирования компонента
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);

    return () => clearTimeout(timer); // Очистить таймер при размонтировании
  }, []);
  const validateForm = () => {
    const newErrors = {};
    for (const field in formData) {
      const error = validateField(field, formData[field]);
      if (error) newErrors[field] = error;
    }
    return newErrors;
  };

  useEffect(() => {
    const validationErrors = validateForm();
    const isFormComplete =
      !Object.keys(validationErrors).length &&
      formData.name.trim() &&
      formData.email.trim() &&
      /\S+@\S+\.\S+/.test(formData.email) &&
      formData.message.trim();

    setIsFormValid(isFormComplete);
  }, [formData]);

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched({ ...touched, [name]: true });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (touched[name]) {
      const fieldError = validateField(name, value);
      setErrors({ ...errors, [name]: fieldError });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsSending(true);
    emailjs
      .sendForm(
        'service_yduw0sb',
        'template_sp7nvpy',
        e.target,
        'Xc13_pAJNjPg6RGjT'
      )
      .then((result) => {
        setSuccessMessage('Message sent successfully!');
        setFormData({
          name: '',
          email: '',
          message: '',
          subject: 'General Inquiry',
        });

        setTimeout(() => {
          setSuccessMessage('');
        }, 5000);
      })
      .catch(() => {
        alert('Failed to send the message. Please try again later.');
      })
      .finally(() => {
        setIsSending(false);
      });
  };

  return (
    <div className="home">
      <Sidebar />
      <div className={`contact-form-content ${isVisible ? 'visible' : ''}`}>
        <h2>Contact Me</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.name && errors.name && <div className="error">{errors.name}</div>}

          <input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.email && errors.email && <div className="error">{errors.email}</div>}

          <select
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            className="dropdown"
          >
            <option value="General Inquiry">General Inquiry</option>
            <option value="Project Question">Question About a Project</option>
            <option value="Collaboration">I Want to Collaborate</option>
            <option value="Other">Other</option>
          </select>

          <textarea
            name="message"
            placeholder="Your Message"
            value={formData.message}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.message && errors.message && <div className="error">{errors.message}</div>}

          <div className="buttons">
            <button
              type="submit"
              disabled={!isFormValid || isSending}
              className={!isFormValid || isSending ? 'disabled-button' : ''}
            >
              {isSending ? 'Sending...' : 'Send Message'}
            </button>
          </div>
        </form>
        {successMessage && <div className="success-message">{successMessage}</div>}
      </div>
    </div>
  );
};

export default ContactForm;
